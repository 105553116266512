import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import { rhythm } from '../../../utils/typography'
import styled from "@emotion/styled";
import Scene from "../../../components/scenes/pump-loader"; // then change it to a directory ../../../components/scenes/pump-loader

import "../../../components/mapstyles.css"



const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${rhythm(25)})  {
    flex-direction: column;
    & > * + * {
      margin-top: ${rhythm(0.5)}; 
    }
    div {
      align-items: center !important;
    }
  }
  
`



const SecondPage = () => (
    <Layout>
        <SEO title="Pump" />
        <div style={{ maxWidth: `2000px`, margin: `0 auto`, position: `absolute`, width:`1200px` }}>
            <Scene />
        </div>
    </Layout>
)

export default SecondPage
