import React, { useRef } from 'react';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { Vector3 } from '@babylonjs/core/Maths/math';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';

import {
    AnimationPropertiesOverride,
    ExecuteCodeAction, FollowCamera,
    InterpolateValueAction,
    StandardMaterial,
    Texture, Tools, UniversalCamera
} from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";
import {PointLight} from "@babylonjs/core";

import {ArcRotateCamera} from "@babylonjs/core";
import {SceneLoader} from "@babylonjs/core";

let box;
let babylonLink;
let skull;


const onSceneReady = scene => {

    // scene.clearColor = new Color3(0.5, 0.8, 0.5);
    // scene.clearColor = new Color3(1, 1, 1)
    scene.clearColor = new Color3(0.0234375,0.0234375,0.0234375); // CHECK


    const canvas = scene.getEngine().getRenderingCanvas();

    // var environment = scene.createDefaultEnvironment({ enableGroundShadow: true, groundYBias: 1 });

    // var camera = new UniversalCamera("UniversalCamera", new Vector3(0, 0, -1), scene);
    var camera = new ArcRotateCamera(
        "camera",
        -Math.PI/2,
        Math.PI/2,
        300,
        new Vector3(0, 0  , 0),
        scene);

    // camera.setTarget(new Vector3(-100,2,0));

    camera.lowerRadiusLimit = 250;
    camera.upperRadiusLimit = 500;

    camera.attachControl(canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;

    //Creates a new standard material and sets the diffuse color to blue.
    var newMaterial = new StandardMaterial;
    newMaterial.name = "newMaterial";
    newMaterial.diffuseColor = new Color3.Blue;

    skull = SceneLoader.ImportMesh("",
        "https://rawcdn.githack.com/BabylonJS/Website/f13b94d32cd8422fd661cd84aaaa396e06b4a657/Scenes/Assets/",
        "skull.babylon",
        scene,
        function(newMeshes)
        {
        var sphere = newMeshes[0].getChildMeshes()[0];
        sphere.material = newMaterial;
    });

}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = scene => {
    // if (box !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //     const rpm = 10;
    //     box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //     box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }
    // if (me !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //         const rpm = 10;
    //         me.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }

}

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">
            </a>
        </>
    )
}
